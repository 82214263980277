import React, { Component } from 'react';
import '../../App.css';
import {
  injectStripe,
} from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import NameSection from '../../components/formSections/NameSection'
import AddressSection from '../../components/formSections/AddressSection'
import PaymentSection from '../../components/formSections/PaymentSection'
import Snackbar from '@material-ui/core/Snackbar';
import dropin from 'braintree-web-drop-in'
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper:{
    marginTop: "5%",
    marginBottom: "5%",
    minWidth: "80%",
    zIndex: '1000'
  },
  button:{
    minWidth: "60%",
    marginTop: "5%"
  },
  textField:{
    marginTop: "1%"
 },
   textFieldHalf:{
      width: '30%',
      float:"left"
  },
  textFieldQuarter:{
     width: '25%',
     float:"left"
 },
  textFieldInLine:{
    marginTop: "1%"
  },
  avatar:{
    marginTop: "3%",
    minHeight: "100px",
    minWidth: "100px"
  },
});


class CardUpdateForm extends React.Component {
  constructor(props){
  super(props);
  this.state ={
    form: {},
    line_items: [],
    plan:{}
  }

}

componentDidMount(){
  var self = this;
  axios.get(`${apiBaseUrl}/v1/org/customers/update/${self.props.match.params.unique_hash}`)
  .then(function (response) {
    if (!response.data.currency) {
      response.data.currency = "usd"
    }

    self.setState({
      ...response.data
    });
    const form = response.data;
    console.log(form)
    if (form.org.braintree_credentials && form.org.braintree_credentials.tokenization_key) {
      const newDropIn = {
        authorization: response.data.org.braintree_credentials.tokenization_key,
        container: '#dropin-container',
        card:{
          cardholderName: true,
        },
        /*overrides:{
          fields:{
            cvv: {
              //selector: '#cvv'
            }
          }
        }*/
      }
      if (form.org && form.org.paypal) {
        newDropIn.paypal = {flow: 'vault'}
      }

      dropin.create(newDropIn).then((instance) => {
        console.log("INSTANCE", instance)
        self.setState({
          "instance": instance //sets the instance in state for future use, liek creating a nonce
        });
      }).catch((err) => {
        console.log(err)
      });
    }
    console.log("STATE", self.state)
  })
  .catch(function (error) {
    if (error.response.status === 404) {
      window.location.href = "/notfound";
    }

  });

}

handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handlePassChange = (name, value) => {
  this.setState({
    [name]: value,
  });
};

handleSubmit = (ev) => {
  ev.preventDefault();
  var self = this;
  const instance = this.state.instance;

  instance.requestPaymentMethod(function (err, payload) {
    console.log(payload)
    if(err){
      console.log(err)
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: err.message
      });
    }else{
      let otherData ={
        address: self.state.address,
        city: self.state.city,
        country: self.state.country,
        state: self.state.state,
        postal_code: self.state.postal_code,
      }


      self.postToken(payload.nonce, payload, otherData);
    }
  });
};

postToken = (token, fullToken, otherData) =>{
  var self = this;
console.log(token, otherData)

axios.put(`${apiBaseUrl}/v1/org/payment-methods/nonce/${self.props.match.params.unique_hash}`,
  {
    token: token,
    full_token: fullToken,
    email: otherData.email,
    first_name: self.state.first_name,
    last_name: self.state.last_name,
    address: otherData.address,
    city: otherData.city,
    country: otherData.country,
    state: otherData.state,
    postal_code: otherData.postal_code,
    zip: otherData.postal_code,
    coupon: otherData.coupon
  })
  .then(function (response) {
    console.log(response);
    self.setState({
      submitting: false
    })
    let url = "/success/update"
    if (self.state.form.redirect_url) {
      url = self.state.form.redirect_url;
    }
    window.location.href = url;
  })
  .catch(function (error) {
    self.setState({
      submitting: false
    })
    if (error.response && error.response.status=== 402) {
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "This card was declined."
      });
    }else if(error.response && error.response.status=== 400 && self.state.form.coupon_enabled){
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "That coupon looks invalid."
      });
    }else{
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "There was an error processing this charge."
      });
    }
    console.log(error);
  });
}

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

  render() {
    const { classes } = this.props;
    const {plan} = this.state
    const lineItems = []
    let total = 0;

    return (
      <div className={"child"}>

      <Paper className={classes.paper}>
      <Grid container justify="center">
      <div style={{maxHeight: "3%", marginTop: "1%"}}>
      {this.state.org && this.state.org.branding && this.state.org.branding.logo ?
      <img src={this.state.org.branding.logo} style={{maxHeight: "120px"}} alt="logo"/>
      :
      null
      }
      </div>
      </Grid>
      <Grid container justify="center">
        <Typography variant="h6">
          Please update your billing details.
        </Typography>
      </Grid>
      <form className={"form"} onSubmit={this.handleSubmit}>
      <Grid container spacing={0} justify="center">

      <AddressSection {...this.props} changeInput={this.handlePassChange}/>


      <Grid item xs={12}>
      <div id="dropin-container"></div>
      </Grid>

      <Grid item xs={12}>

      </Grid>
        <Button
        variant="contained"
        color="primary"
        style={{backgroundColor: this.state.primary_color || "#814ff8"}}
        className={classes.button}
        disabled={this.state.submitting}
        type="submit"
        >
          { this.state.submitting ? <CircularProgress className={classes.progress} /> : "Update Payment Method"}
        </Button>
        </Grid>
      </form>

      </Paper>
      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={this.state.snackOpen}
      autoHideDuration={2000}
      onClose={this.handleSnackClose}
      >
      <MySnackbarContentWrapper
      variant={this.state.snackVariant}
      message={this.state.snackMessage}
      onClose={this.handleSnackClose}
      />
      </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(CardUpdateForm);
