import React from 'react';
import {CardElement} from 'react-stripe-elements';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  textField:{
    maxWidth: "68%",
    marginTop: "1%"
  },
  textFieldInLine:{
    marginTop: "1%"
  },
  inlineDiv:{
    maxWidth: "68%"
  },

});

class NameSection extends React.Component {

  passChange = name => event => {
    this.props.changeInput(name, event.target.value)
  };

  render() {
    const { classes } = this.props;
    return (
    <Grid container spacing={0} justify="center">
    <Grid item xs={12} sm={6}>
    <TextField
    label="First Name"
    fullWidth
    required
    className={classes.textField}
    onChange={this.passChange('first_name')}
    />
    </Grid>
    <Grid item xs={12} sm={6}>
    <TextField
    label="Last Name"
    fullWidth
    required
    className={classes.textField}
    onChange={this.passChange('last_name')}
    />
    </Grid>
    <TextField
    label="Email"
    type="email"
    fullWidth
    required
    className={classes.textField}
    onChange={this.passChange('email')}
    />

    </Grid>
    );
  }
}

export default NameSection;
