import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom'
import { Redirect } from 'react-router';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Login from './views/login/LoginPage.js';
import Register2 from './views/register/RegisterPage.js';
import CheckoutPage from './views/forms/FormPage.js';
import OneTimePage from './views/forms/OneTimeFormPage.js';
import Success from './views/SuccessPage.js';
import NotFound from './views/NotFound.js';
import CardUpdatePage from './views/forms/CardUpdatePage.js';
import LookupPage from './views/forms/LookupPage.js';
import logo from './logo.svg';
import './App.css';


const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: {
      light: '#f2f7fe',
      main: '#B0BEC5',
      dark: '#B0BEC5',
      contrastText: '#fff',
    },
  }
});

class PrimaryLayout extends Component {
  render() {
    return (
      <div className="primary-layout">
        <main>
        <Switch>

        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Register2} />
        <Route path="/forms/signup/:id" exact component={CheckoutPage} />
        <Route path="/forms/onetime/:id" exact component={OneTimePage} />
        <Route path="/find/:orgId" exact component={LookupPage} />
        <Route path="/forms/update/:unique_hash" exact component={CardUpdatePage} />
        <Route path="/success/:type" exact component={Success} />
        <Route exact component={NotFound} />
      </Switch>
        </main>
        </div>
    );
  }
}

const App = () => (
  <BrowserRouter>
  <MuiThemeProvider theme={theme}>
  <PrimaryLayout />
  </MuiThemeProvider>
  </BrowserRouter>
)

export default App;
