import React from 'react';
import {CardElement} from 'react-stripe-elements';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';

class AddressSection extends React.Component {

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  passChange = name => event => {
    this.props.changeInput(name, event.target.value)
  };

  render() {
    const { classes } = this.props;
    return (
    <Grid container spacing={0} justify="center">
      <TextField
      label="Address"
      fullWidth
      required
      className={classes.textField}
      onChange={this.passChange('address')}
      />
      <Grid item xs={12} sm={3}>
      <TextField
      label="City"
      fullWidth
      required
      onChange={this.passChange('city')}
      />
      </Grid>
      <Grid item xs={12} sm={3}>
      <TextField
      label="State/Province"
      fullWidth
      required
      onChange={this.passChange('state')}
      />
      </Grid>
      <Grid item xs={12} sm={3}>
      <TextField
      label="Postal Code"
      fullWidth
      required
      onChange={this.passChange('postal_code')}
      />
      </Grid>
      <Grid item xs={12} sm={3}>
      <TextField
      label="Country"
      fullWidth
      required
      onChange={this.passChange('country')}
      />
      </Grid>
      </Grid>
    );
  }
}

export default AddressSection;
