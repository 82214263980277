import React from 'react';
import {CardElement} from 'react-stripe-elements';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import dropin from 'braintree-web-drop-in'
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
const styles = theme => ({
  textField:{
    maxWidth: "68%",
    marginTop: "1%"
  },
  textFieldInLine:{
    marginTop: "1%"
  },
  inlineDiv:{
    maxWidth: "68%"
  },

});



class PaymentSection extends React.Component {

  componentDidMount(){

  }

  passChange = name => event => {
    this.props.changeInput(name, event.target.value)
  };

  render() {
    const { classes } = this.props;
    return (

    <Grid item xs={12}>
    <div id="dropin-container"></div>
    </Grid>

    );
  }
}

export default PaymentSection;
