import React, { Component } from 'react';
import '../App.css';
import {
  injectStripe,
} from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import NameSection from '../components/formSections/NameSection'
import AddressSection from '../components/formSections/AddressSection'
import PaymentSection from '../components/formSections/PaymentSection'
import Snackbar from '@material-ui/core/Snackbar';
import dropin from 'braintree-web-drop-in'
import green from '@material-ui/core/colors/green';
import CheckIcon from '@material-ui/icons/Check';
import Fab from '@material-ui/core/Fab';
import MySnackbarContentWrapper from '../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper:{
    marginTop: "5%",
    marginBottom: "5%",
    minWidth: "80%",
    height: 600,
    zIndex: '1000'
  },
  button:{
    minWidth: "60%",
    marginTop: "5%"
  },
  textField:{
    marginTop: "1%"
 },
   textFieldHalf:{
      width: '30%',
      float:"left"
  },
  textFieldQuarter:{
     width: '25%',
     float:"left"
 },
  textFieldInLine:{
    marginTop: "1%"
  },
  avatar:{
    marginTop: "3%",
    minHeight: "100px",
    minWidth: "100px"
  },
  fab:{
    marginTop: "25%",
    height: 100,
    width: 100,
    backgroundColor: green[600],
    '&:hover': {
    backgroundColor: green[600],
    },
    //position: "absolute"


  }
});


class Success extends React.Component {
  constructor(props){
  super(props);
  this.state ={
    form: {},
    line_items: [],
    plan:{}
  }

}

componentDidMount(){


}

handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handlePassChange = (name, value) => {
  this.setState({
    [name]: value,
  });
};

handleSubmit = (ev) => {
  ev.preventDefault();
  var self = this;
  const instance = this.state.instance;

  instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
    console.log(payload)
    if(requestPaymentMethodErr){
      //display err
    }else{
      let otherData ={
        email: self.state.email,
        first_name: self.state.first_name,
        last_name: self.state.last_name,
        address: self.state.address,
        city: self.state.city,
        country: self.state.country,
        state: self.state.state,
        postal_code: self.state.postal_code,
      }


      self.postToken(payload.nonce, payload, otherData);
    }
  });
};

postToken = (token, fullToken, otherData) =>{
  var self = this;
console.log(token, otherData)

axios.put(`${apiBaseUrl}/v1/org/payment-methods/nonce/${self.props.match.params.unique_hash}`,
  {
    token: token,
    full_token: fullToken,
    email: otherData.email,
    first_name: self.state.first_name,
    last_name: self.state.last_name,
    address: otherData.address,
    city: otherData.city,
    country: otherData.country,
    state: otherData.state,
    postal_code: otherData.postal_code,
    zip: otherData.postal_code,
    coupon: otherData.coupon
  })
  .then(function (response) {
    console.log(response);
    self.setState({
      submitting: false
    })
    let url = "/success"
    if (self.state.form.redirect_url) {
      url = self.state.form.redirect_url;
    }
    window.location.href = url;
  })
  .catch(function (error) {
    self.setState({
      submitting: false
    })
    if (error.response && error.response.status=== 402) {
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "This card was declined."
      });
    }else if(error.response && error.response.status=== 400 && self.state.form.coupon_enabled){
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "That coupon looks invalid."
      });
    }else{
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "There was an error processing this charge."
      });
    }
    console.log(error);
  });
}

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

  render() {
    const { classes } = this.props;
    const {plan} = this.state
    const lineItems = []
    let total = 0;

    return (
      <div className={"child"}>

      <Paper className={classes.paper}>
      <Grid container justify="center">
      <Fab color="green"
      className={classes.fab}
      >
       <CheckIcon />
      </Fab>
      </Grid>
      <Grid container justify="center">
      <Typography variant="h6">
      { this.props.match.params.type === "signup" ? "Success!":"Thank you! Your payment profile has been successfully updated."}
      </Typography>
      </Grid>
      </Paper>
      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={this.state.snackOpen}
      autoHideDuration={2000}
      onClose={this.handleSnackClose}
      >
      <MySnackbarContentWrapper
      variant={this.state.snackVariant}
      message={this.state.snackMessage}
      onClose={this.handleSnackClose}
      />
      </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(Success);
