import React, { Component } from 'react';
import '../../App.css';
import {
  injectStripe,
} from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import NameSection from '../../components/formSections/NameSection'
import AddressSection from '../../components/formSections/AddressSection'
import PaymentSection from '../../components/formSections/PaymentSection'
import Snackbar from '@material-ui/core/Snackbar';
import dropin from 'braintree-web-drop-in'
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  root: {
    flexGrow: 1,
  },

  paper:{
    marginTop: "5%",
    marginBottom: "5%",
    minWidth: "80%",
    zIndex: '1000'
  },
  button:{
    minWidth: "60%",
    marginTop: "5%"
  },
  textField:{
    marginTop: "1%"
 },
   textFieldHalf:{
      width: '30%',
      float:"left"
  },
  textFieldQuarter:{
     width: '25%',
     float:"left"
 },
  textFieldInLine:{
    marginTop: "1%"
  },
  avatar:{
    marginTop: "3%",
    minHeight: "100px",
    minWidth: "100px"
  },
  submitting:{
    color: "#000"
  },
  loader:{
  marginTop: "30%"
}
});


class OneTimePage extends React.Component {
  constructor(props){
  super(props);
  this.state ={
    form: {},
    line_items: [],
    plan:{},
    firstLoadAttempted: false
  }

}

componentDidMount(){
  var self = this;
  axios.get(`${apiBaseUrl}/v1/org/forms/${self.props.match.params.id}`)
  .then(function (response) {
    if (!response.data.currency) {
      response.data.currency = "usd"
    }

    self.setState({
      ...response.data,
      firstLoadAttempted: true
    });
    const form = response.data;
    if (form.org.braintree_credentials && form.org.braintree_credentials.tokenization_key) {

      const newDropIn = {
        authorization: form.livemode === true ? form.org.braintree_credentials.tokenization_key : form.org.braintree_credentials.sandbox_tokenization_key,
        container: '#dropin-container',
        /*overrides:{
          fields:{
            cvv: {
              //selector: '#cvv'
            }
          }
        }*/
      }
      if (form.paypal) {
        newDropIn.paypal = {flow: 'vault'}
      }

      dropin.create(newDropIn).then((instance) => {
        console.log("INSTANCE", instance)
        self.setState({
          "instance": instance //sets the instance in state for future use, liek creating a nonce
        });
      }).catch((err) => {
        console.log(err)
      });
    }
    console.log("STATE", self.state)
  })
  .catch(function (error) {
    if (error.response.status === 404) {
        window.location.href = "/notfound";
    }
    self.setState({
      firstLoadAttempted: true
    });
  });

}

handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handlePassChange = (name, value) => {
  this.setState({
    [name]: value,
  });
};

handleSubmit = (ev) => {
  ev.preventDefault();
  var self = this;
  const instance = this.state.instance;

  self.setState({
    submitting: true
  })

  instance.requestPaymentMethod(function (err, payload) {
      if(err){
        console.log(err)
        self.setState({
          submitting: false,
          snackOpen: true,
          snackVariant:"error",
          snackMessage: err.message
        });
    }else{
      let otherData ={
        email: self.state.email,
        first_name: self.state.first_name,
        last_name: self.state.last_name,
        address: self.state.address,
        city: self.state.city,
        country: self.state.country,
        state: self.state.state,
        postal_code: self.state.postal_code,
      }


      self.postToken(payload.nonce, payload, otherData, instance);
    }
  });
};

postToken = (token, fullToken, otherData, instance) =>{
  var self = this;
console.log(token, otherData)
axios.post(`${apiBaseUrl}/v1/org/forms/onetime/${self.props.match.params.id}`,
  {
    token: token,
    full_token: fullToken,
    email: otherData.email,
    first_name: self.state.first_name,
    last_name: self.state.last_name,
    address: otherData.address,
    city: otherData.city,
    country: otherData.country,
    state: otherData.state,
    postal_code: otherData.postal_code,
    zip: otherData.postal_code,
    coupon: otherData.coupon
  })
  .then(function (response) {
    console.log(response);
    self.setState({
      submitting: false
    })
    let url = "/success/signup"
    if (self.state.form.redirect_url) {
      url = self.state.form.redirect_url;
    }
    window.location.href = url;
  })
  .catch(function (error) {

    if (error.response && error.response.data && error.response.data.message) {
      instance.clearSelectedPaymentMethod();
      self.setState({
        submitting: false,
        snackOpen: true,
        snackVariant:"error",
        snackMessage: error.response.data.message
      });
    }else{
      self.setState({
        submitting: false,
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "Error processing charge."
      });
    }

    console.log(error);
  });


}

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

  render() {
    const { classes } = this.props;
    const {amount} = this.state
    const lineItems = []
    let total = 0;

    total = total + Number(amount)
    lineItems.push(
      <tr className="line-item line-product">
            <td>{this.state.description}</td>

            <td>{`${getSymbolFromCurrency(this.state.currency)}${(Number(amount)).toFixed(2)}`}</td>
          </tr>
        )

  console.log("total after ")

  if (this.state.form && this.state.form.setup_fee && this.state.form.setup_fee.active) {
    total = total + Number(this.state.form.setup_fee.amount_in_cents);
    lineItems.push(
      <tr className="line-item line-product">
            <td>Initial Fee</td>
            <td>{`${getSymbolFromCurrency(this.state.form.currency)}${((Number(this.state.form.setup_fee.amount_in_cents)/100)).toFixed(2)}`}</td>
          </tr>
        )
  }

  if (this.state.form && this.state.form.coupon_enabled) {

  }

  if (this.state.form && this.state.form.taxes && this.state.form.taxes.active) {
    lineItems.push(
      <tr className="line-item line-product">
            <td>Taxes {Math.round(this.state.form.taxes.percent* 100) / 100}%</td>
            <td>{getSymbolFromCurrency(this.state.form.currency)}{(total * (Number(this.state.form.taxes.percent)/100)).toFixed(2)}</td>
          </tr>
        )

    total = total + (total * (Number(this.state.form.taxes.percent)/100)) //this must be last so the tax line item calculates the right amount
  }

    return (
      <div className={"child"}>
      {this.state.firstLoadAttempted ?
        <div>
      <Paper className={classes.paper}>
      <Grid container justify="center">
      <div style={{maxHeight: "3%", marginTop: "1%"}}>
      {this.state.org && this.state.org.branding && this.state.org.branding.logo ?
      <img src={this.state.org.branding.logo} style={{maxHeight: "120px"}} alt="logo"/>
      :
      null
      }
      </div>
      </Grid>
      <Grid container justify="center">
      <div id="line-items">
        <table className="item-table">
        <tbody>
        {lineItems}

          </tbody>
          <tfoot>
          <tr className="line-item total">
            <td><strong>TOTAL</strong></td>

            <td><span id="checkout-total">{getSymbolFromCurrency(this.state.currency)}{total.toFixed(2)}</span></td>
          </tr>
          </tfoot>
        </table>
        <Divider/>
      </div>
      </Grid>
      <form className={"form"} onSubmit={this.handleSubmit}>
      <Grid container spacing={0} justify="center">
      <NameSection {...this.props} changeInput={this.handlePassChange}/>
      <AddressSection {...this.props} changeInput={this.handlePassChange}/>

      {this.state.form && this.state.form.coupon_enabled ?
        <TextField
        label="Promo Code"
        fullWidth
        className={classes.textField}
        onChange={this.handleChange('coupon')}
        />
        :
        null
      }
      <Grid item xs={12}>
      <div id="dropin-container"></div>
      </Grid>

      <Grid item xs={12}>

      </Grid>
        <Button
        variant="contained"
        color="primary"
        style={{backgroundColor: this.state.org && this.state.org.branding ? this.state.org.branding.primary_color : "#814ff8"}}
        className={classes.button}
        disabled={this.state.submitting}
        type="submit"
        >
          { this.state.submitting ? <CircularProgress className={classes.submitting} /> : "Complete Checkout"}
        </Button>
        </Grid>
      </form>

      </Paper>
      </div>
      :
  <Grid container justify={"center"}>
  <div className={classes.loader}>
  <CircularProgress size={100} color="primary" className={classes.progress} />
  </div>
  </Grid>
    }
      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={this.state.snackOpen}
      autoHideDuration={2000}
      onClose={this.handleSnackClose}
      >
      <MySnackbarContentWrapper
      variant={this.state.snackVariant}
      message={this.state.snackMessage}
      onClose={this.handleSnackClose}
      />
      </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(OneTimePage);
