import React, { Component } from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import {
  injectStripe,
} from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../components/messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper:{
    marginTop:"10%",
    minHeight: "70%",
    minWidth: "80%",
    zIndex: '1000'
  },
  button:{
    minWidth: "60%",
    marginTop: "5%"
  },
  textField:{
    marginTop: "1%"
 },
   textFieldHalf:{
      width: '30%',
      float:"left"
  },
  textFieldQuarter:{
     width: '25%',
     float:"left"
 },
  textFieldInLine:{
    marginTop: "1%"
  },
interval:{
  fontSize: "12px"
},
loader:{
marginTop: "30%"
}
});


class FindForm extends React.Component {
  constructor(props){
  super(props);
  this.state ={
    logo:`https://s3-us-west-2.amazonaws.com/instapayments/${this.props.match.params.orgId}_${this.props.match.params.org}`,
  }

}

componentDidMount(){
  var self = this;
  axios.get(`${apiBaseUrl}/v1/org/forms/branding/${self.props.match.params.orgId}`)
  .then(function (response) {

      self.setState({
       ...response.data,
       firstLoadAttempted: true
      });


  })
  .catch((error) => {
    console.log(error)
    self.setState({
      submitting: false,
      snackOpen: true,
      firstLoadAttempted: true,
      snackVariant:"error",
      snackMessage:"Error loading form."
    })
  });
}

handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handleSubmit = (ev) => {
  ev.preventDefault();
  var self = this;
axios.post(`${apiBaseUrl}/v1/org/payment-methods/find/${self.props.match.params.orgId}`,
  {
    email: this.state.email.toLowerCase(),
  })
  .then(function (response) {
    self.setState({
      submitting: false,
      snackOpen: true,
      snackVariant:"success",
      snackMessage:"Your unique link was sent to your email."
    })
  })
  .catch(function (error) {
    self.setState({
      submitting: false,
    })
    console.log(error.response)
    let errorResponse = "Somethign went wrong retireving customer profile."
    if (error.response && error.response.status == 404) {
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "Could not find customer by that email."
      });
    }else{
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: errorResponse
      });
    }
  });
}


handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

  render() {
    const { classes } = this.props;
    console.log(this.state)

    return (
      <div className={"centered-child"}>
      {this.state.firstLoadAttempted ?

      <Paper className={"paper"}>
      <Grid container justify="center">
      <div style={{maxHeight: "3%", marginTop: "5%"}}>
      {this.state.logo ?
      <img src={this.state.logo} style={{maxHeight: "120px"}} alt="logo"/>
      :
      null
      }
      </div>
      </Grid>
      <Grid container justify="center">
      <Typography>
      Get your unique card update link sent to your email.
      </Typography>
      </Grid>
      <form className={"form"} onSubmit={this.handleSubmit}>
      <Grid container spacing={0} justify="center">

      <TextField
      label="Email"
      fullWidth
      required
      type="email"
      className={classes.textField}
      onChange={this.handleChange('email')}
      />
        <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{backgroundColor: this.state.primary_color || "#814ff8"}}
        className={classes.button}
        disabled={this.state.submitting}
        type="submit"
        >
          { this.state.submitting ? <CircularProgress className={classes.progress} /> : "Get My Update Link" }
        </Button>
        </Grid>
      </form>
      </Paper>
      :
      <Grid container justify={"center"}>
      <div className={classes.loader}>
      <CircularProgress size={100} color="primary" className={classes.progress} />
      </div>
      </Grid>
    }

      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={this.state.snackOpen}
      autoHideDuration={3500}
      onClose={this.handleSnackClose}
      >
      <MySnackbarContentWrapper
      variant={this.state.snackVariant}
      message={this.state.snackMessage}
      onClose={this.handleSnackClose}
      />
      </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(FindForm);
